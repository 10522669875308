import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import './repo/extra';
import './assets/css/iconfont.css'
import './assets/css/animate.css'

import 'cropperjs/src/index.css';

import './layout/layout.less';

import sweetAlert from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import moment from 'moment';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import loading from "./repo/loading";
import vueOnScreen from 'vue-on-screen'
import auth from "@/auth";

Vue.use(sweetAlert);

if (process.env.NODE_ENV === 'development') {
    // localStorage['token'] = '0300c2551bea4629d3962283f8093f13064d2782'
    localStorage['token'] = '993adc96ced6a075f6891f8923751e0d3dd0c5df'

    // 151322
    // localStorage['token'] = 'dc13ed169d73e2d5dd387226af14ea70ba797f5c'
}

Vue.use(PerfectScrollbar);

Vue.prototype.$moment = moment;

Vue.use(Antd);

Vue.prototype.$formatTime = function (time, format) {

    if (!format) format = 'yyyy-MM-DD HH:mm';

    return moment(time).format(format)
}

const isObject = (obj) => Object.prototype.toString.call(obj) === "[object Object]";

Vue.prototype.$deepMerge = function (target, source) {
    if (isObject(target) && isObject(source)) {
        // 遍历源对象，将源对象存在但目标对象不存在的属性赋值给目标对象
        for (const key in source) {
            if (!target[key]) {
                target[key] = source[key];
            }
        }
        // 遍历目标对象
        for (const key in target) {
            if (source[key]) {
                // 判断属性值数据类型，若不是对象类型则赋值给目标对象
                if (!isObject(source[key])) {
                    target[key] = source[key];
                } else {
                    // 是对象类型则递归调用
                    this.$deepMerge(target[key], source[key]);
                }
            }
        }
    }
};

Vue.prototype.$qrcode = function (path, name) {


    let baseUrl = 'https://api.yanchuang.ycsd.work/api/collocation/com/mini-qrcode?path=';

    baseUrl += encodeURI(path);
    baseUrl += '&token=' + auth.getToken()
    baseUrl += '&name=' + name;
    baseUrl += '&_mini_app_id=' + auth.appId()

    baseUrl = encodeURI(baseUrl);

    console.log(baseUrl);

    return baseUrl;

}

Vue.use(loading)

Vue.use(vueOnScreen, {
    minShowHeight: 20,//默认的最小展示高度判断设置
    minShowWidth: 1,//默认的最小展示宽度判断设置
})

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
